import React from "react";
import { Link, graphql } from "gatsby";
import ProductCategoryTile from "../../components/ProductCategoryTile";
import Layout from "../../components/Layout";

const splitEvery = (array, length) =>
  array.reduce((result, item, index) => {
    if (index % length === 0) result.push([]);
    result[Math.floor(index / length)].push(item);
    return result;
  }, []);

const indexPage = ({ data }) => {
  let items = splitEvery(data.allMarkdownRemark.edges, 3);

  return (
    <Layout>
      <div className="section">
        <div className="container">
          <h1 className="is-size-1">All Products</h1>
          <br />
          {/* <div>Click on them for more details</div>
          <br />
           */}
          {items.map(nodes => (
            <div className="columns">
              {nodes.map(node => (
                <div key={node.id} className="column is-one-third">
                  <ProductCategoryTile
                    item={{
                      title: node.node.frontmatter.title,
                      image: node.node.frontmatter.cover,
                      path: node.node.frontmatter.slug
                    }}
                  />
                  {/* <div className="box">
                {node.node.frontmatter.title} {node.node.frontmatter.cover}
              </div> */}
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query Products {
    allMarkdownRemark(
      filter: { frontmatter: { templateKey: { eq: "product-page" } } }
      sort: { order: ASC, fields: frontmatter___sortOrder }
    ) {
      totalCount
      edges {
        node {
          id
          frontmatter {
            title
            cover
            slug
          }
        }
      }
    }
  }
`;

export default indexPage;