import React from "react";
import { Link } from "gatsby";

const ProductCategoryTile = ({ item }) => {
  return (
    <Link to={`/products/` + item.path} >
    <div className="card hover-card">
      <div className="card-image">
        <figure className="image is-4by3">
          <img alt={item.title} src={item.image} />
        </figure>
      </div>
      <div className="card-content">
        <div className="content has-text-centered">
            {item.title}
        </div>
      </div>
    </div>
    </Link>
  );
};

export default ProductCategoryTile;
